@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {
      .user-btn-flex {
            margin-top: 10px;
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}