@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {
      .user-list-flex {
            display: flex;
            align-items: self-start;
            flex-direction: column;
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}