/* start admin login css */

.admin-auth-main-flex {
      display: flex;
      height: 100vh;
      justify-content: center;
      align-items: center;
}

.admin-auth-left-main {
      width: 100%;
      max-width: 70%;
      flex: 0 0 70%;
}

.admin-auth-right-main {
      width: 100%;
      max-width: 30%;
      flex: 0 0 30%;
}

.admin-auth-left-bg {
      height: 100vh;
      object-fit: cover;
      background-repeat: no-repeat;
      background-image: url(../../../assets/images/png/login-bg.jpg);
      background-size: cover;
      background-position: right;
      width: 100%;
}

.admin-auth-left-bg-main {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      height: 100vh;
      width: 70%;
}

.admin-auth-main {
      max-width: 350px;
      margin: auto;
      width: 100%;
}

.admin-auth-box {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
}

.admin-login-btn-main .admin-login-btn {
      margin-top: 28px;
      width: 100%;
}

.admin-checkbox-main .admin-checkbox-lable {
      color: var(--secondary-color);
      margin-left: 0px;
      margin-right: 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
}

.admin-checkbox-main {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
}

.admin-checkbox-main .MuiButtonBase-root {
      margin-right: 10px;
      padding: 0px;
}


.admin-checkbox-lable .MuiFormControlLabel-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
}

.admin-auth-inner-main .admin-wel-text {
      color: var(--primary-color);
      font-size: 24px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 10px;
}

.admin-auth-inner-main .admin-sign-para {
      margin-bottom: 20px;
      color: var(--secondary-color);
}

.admin-forgot-row .admin-forgot-para {
      color: var(--selected-color);
      text-decoration: none;
      font-weight: 500;
}

/* end admin login css */







/* start or text design */

.admin-or-text {
      text-align: center;
      color: var(--secondary-color);
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      margin: 30px 0px 0px;
      position: relative;
      max-width: max-content;
      background-color: #fff;
}

.admin-condition-content {
      text-align: center;
}

.admin-condition-content .admin-condition-text {
      color: var(--secondary-color);
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
}

.admin-condition-content .admin-condition-span {
      color: var(--selected-color);
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      text-decoration: none;
}

.admin-or-text-main {
      position: relative;
      margin: 30px 0px;
}

.admin-or-text-main .admin-or-cocntent-text {
      background: #f4f5f7;
      margin: auto;
      z-index: 9;
      position: relative;
      text-align: center;
      width: 30px;
      color: var(--secondary-color);
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
}

.admin-or-text-main::after {
      content: '';
      width: 100%;
      height: 1px;
      background: #d3d5d6;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
}

/* end or text design */


/* start otp page */

.admin-otp-flex-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
}

.admin-form-control .MuiFormControl-root {
      width: 100%;
}

.admin-otp-input-box {
      width: 100%;
}

/* end otp page */


/* start chnage password page css */




/* end change password page css */

.form-lable-otp {
      margin-bottom: 8px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px !important;
      margin-top: 16px !important;
      font-weight: 600 !important;
}

.justify-content-end {
      justify-content: end;
}

.login-otp {
      /* display: flex; */
      width: 100%;
}

.login-otp input {
      width: 3.5em !important;
      height: 3.5em;
      border: 1px solid #b6b6b6;
}

/* .user-forgot-row .user-forgot-para {
      color: #fff !important;
} */


.login-otp div {
      justify-content: space-between;
}



/* admin new css */

.user-auth-main {
      /* background-color: #151b3c; */
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-image: url(./../../../assets/images/video/bg-1.mp4); */
      /* // background-image: url(./../../../assets/images/png/bg1.jpg);
      // background-image: url(./../../../assets/images/png/bg2.jpg);
      // background-image: url(./../../../assets/images/png/bg3.jpg);
      // background-image: url(./../../../assets/images/png/bg4.jpg); */
      background-size: cover;
      width: 100%;
      background-repeat: no-repeat;
      position: relative;
      background-image: url(../../../assets/images/png/mainBackground.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

}

.auth-video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
}

.user-auth-card-main {
      margin: auto;
      position: relative;
      max-width: 450px;
      min-width: 450px;
      /* background-color: #ffffff05; */
      background-color: #fff;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      border: 2px solid #8b84841a;
      box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
      padding: 30px;
}

.user-auth-card-main .user-auth-title {
      font-size: 22px;
      line-height: 26px;
      /* color: var(--main-white); */
      /* background: -webkit-linear-gradient(167deg, #0093E9 0%, #0093E9 100%); */
      background-color: #0055ff !important;
      background-image: linear-gradient( 167deg,  #0055ff 0%, #043873 100% ) !important;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      margin-bottom: 20px;
}

.user-auth-card-main .user-auth-para {
      font-size: 14px;
      line-height: 17px;
      /* color: var(--off-white); */
      /* background: -webkit-linear-gradient(167deg, #0093E9 0%, #0093E9 100%); */
      background-color: #0055ff !important;
      background-image: linear-gradient( 167deg,  #0055ff 0%, #043873 100% ) !important;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      margin-bottom: 20px;
}


.user-form-group .MuiInputAdornment-root svg {
      color: #ffffff73;
}


.user-login-btn-main {
      margin-top: 40px;
}

.btn-main-primary.user-login-btn-main .user-login-btn,
.btn-main-primary.user-login-btn-main .user-login-btn:hover {
      width: 100%;
      /* background-color: var(--dark-pink); */
      font-size: 14px;
      font-weight: 600;
}


.user-condition-content {
      margin: 30px 0px 0px;
}

.user-condition-content .user-condition-text {
      font-size: 13px;
      line-height: 20px;
      color: var(--secondary-color);
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
}

.user-condition-content .user-condition-span {
      font-size: 13px;
      line-height: 20px;
      color: var(--main-white);
      font-weight: 400;
      margin-bottom: 20px;
      text-decoration: none;
}


.user-checkbox-main {
      display: flex;
      align-items: center;
      gap: 10px;
}

.user-checkbox-main .MuiCheckbox-root {
      padding: 0px;
}

.user-checkbox-lable {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
      margin-left: 10px;
}


.user-forgot-row .user-forgot-para {
      font-size: 14px;
      line-height: 17px;
      color: #111;
      font-weight: 600;
      text-decoration: none;
}

.user-otp-flex-main {
      margin-bottom: 25px;
}

.user-forgot-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

/* .user-input-box {
      margin-bottom: 20px;
} */

.user-input-box .MuiInputBase-root {
      width: 100%;
      /* background-color: #1b698e1f; */
      background-color: #2841e71f;
}

.user-input-box .MuiInputBase-root fieldset:hover {
      border-color: transparent;
}

.user-form-group .user-form-control-eye {
      /* border: 1px solid var(--light-border); */
      /* background-color: #1b698e1f; */
      background-color: #2841e71f;
      padding-right: 0;
      border-radius: 0px;
}

.user-form-group .user-form-control-eye button {
      margin-right: 0;
}

.user-form-group .user-form-control.user-form-control-eye input {
      border: 0;
}

.user-input-box .user-form-control-eye input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      opacity: 1;
}

/* .user-input-box {
      margin-bottom: 20px;
} */

.user-input-box .user-form-lable {
      font-size: 12px;
      line-height: 17px;
      color: #111 !important;
      /* background: -webkit-linear-gradient(167deg, #0093E9 0%, #0093E9 100%); */
      /* -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      font-weight: 600;
      margin-bottom: 5px;
}

.user-form-group .user-form-control input,
.user-form-group textarea.user-form-control {
      border: 1px solid var(--light-border);
      border-radius: 0px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: #111 !important;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
}

.user-form-group textarea.user-form-control {
      height: 60px !important;
      resize: none !important;
}

.user-form-group .user-form-control input::placeholder,
.user-form-group textarea.user-form-control::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

.user-form-group .user-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.user-form-control fieldset {
      border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--light-border) !important;
}

.user-form-control-textarea:focus-visible {
      outline: none !important;
}

.user-checkbox-main .user-checkbox-lable {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
}

.css-1t2iwl1-MuiInputBase-root-MuiFilledInput-root::before,
.css-1t2iwl1-MuiInputBase-root-MuiFilledInput-root::after {
      display: none;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
      filter: invert(1);
}