@media (max-width: 1249px) {}

@media (max-width: 1023px) {
      .admin-body-overflow {
            overflow: hidden;
      }

      .admin-user-list-flex {
            align-items: flex-start;
            flex-direction: column;
      }
}

@media (max-width: 991px) {
      .admin-user-list-flex {
            flex-direction: row;
      }

      .page-title {
            gap: 8px;
      }
}

@media (max-width: 767px) {
      .admin-admin-dashboard-inner-box {
            max-width: 100%;
      }

      .admin-dashboard-content .admin-user-list-page-title {
            margin-bottom: 10px;
      }

      .admin-filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .admin-progress-bar-text {
            right: 0px;
            top: -23px;
      }

      .main-panel {
            padding: 16px;
      }

      .column-two {
            column-count: 1;
      }

      .qr-code-box .qr-code-cont-box {
            width: 100%;
      }

      .qr-descrip {
            column-count: 2;
      }


      .qr-code.web-img {
            width: 100px;
            height: 100px;
      }

      .qr-descrip.two-column .qr-box-details .qr-title {
            font-size: 12px;
      }

      .qr-descrip.two-column .qr-box-details .qr-subtitle {
            font-size: 12px;
      }

      .qr-subtitle.xlg-font {
            font-size: 22px;
      }

      .date-subtitle {
            font-size: 12px !important;
      }

      .qr-subtitle.sm-font {
            font-size: 12px;
            color: #0093E9;
            font-weight: bold;
      }

}

@media (max-width: 549px) {
      .table-pagination ul {
            justify-content: center;
      }

      .chart-row-main {
            grid-template-columns: 1fr;
      }

      .qr-code-box .qr-code-cont-box.qr-two-box .qr-icon {
            width: 180px;
            height: 180px;
            margin: 0 auto;
            /* background: #1472b1; */
            border-width: 4px;
            border-style: solid;
            border-image: linear-gradient(to right, #17304e, #0f8e89) 1;
            padding: 10px;
            border-radius: 6px;
      }

      .qr-descrip.two-column {
            column-count: 1;
      }

      .qr-descrip {
            column-count: 1;
      }

      .qr-code-box .qr-code-cont-box {
            padding: 8px;
      }

}

@media (max-width: 480px) {
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
            width: 100%;
      }

      .navbar .navbar-menu-wrapper {
            border-left: 0;
      }

      .navbar .navbar-brand-wrapper {
            border-bottom: 0;
      }

      .page-table-main .table-th {
            font-size: 12px;
      }

      .page-table-main .table-td {
            font-size: 11px;
            word-break: normal;
      }

      .custom-label-title,
      .custom-label-subtitle {
            font-size: 12px;
      }

      .table-pagination .css-198z3u6-MuiButtonBase-root-MuiPaginationItem-root {
            min-width: 24px;
            height: 24px;
      }

      .admin-table-data-btn img {
            width: 16px;
      }

      .qr-descrip.two-column {
            column-count: 1;
      }

      .scan-code__angle:before {
            left: 20%;
      }

      .scan-code__angle:after {
            right: 20%;
      }
}

@media(max-width:414px) {

      .scan-code__angle:before {
            left: 18%;
      }

      .scan-code__angle:after {
            right: 18%;
      }
}
@media(max-width:390px) {

      .scan-code__angle:before {
            left: 17%;
      }

      .scan-code__angle:after {
            right: 17%;
      }
}
@media (max-width: 375px) {
      .main-page-logo img {
            width: auto;
      }

      .user-auth-card-main {
            max-width: 90% !important;
            min-width: 90% !important;
            padding: 16px;
      }

      .user-login-btn-main {
            margin-top: 26px;
      }

      .admin-user-list-flex {
            flex-direction: column;
      }

      .page-title {
            font-size: 20px;
      }

      .admin-user-list-flex> :nth-child(2) {
            align-self: flex-end;
            justify-content: end;
      }

      .admin-user-list-flex .back-btn-box> :nth-child(2) {}

      .user-form-group .user-form-control input {
            padding: 6px 12px;
      }

      .list-style-1>li {
            flex-direction: column;
            align-items: start;
            padding: .25rem 0;
            word-break: break-all;
      }

      .img-detail-box {
            width: 100%;
            height: 180px;
      }

      .img-detail-box img {
            object-fit: contain;
      }

      .qr-descrip {
            column-count: 1;
      }

      .scan-code__angle:before {
            left: 15%;
      }

      .scan-code__angle:after {
            right: 15%;
      }
}

@media (max-width: 320px) {
      .scan-code__angle:before {
            left: 10%;
      }

      .scan-code__angle:after {
            right: 10%;
      }
}