@media (max-width: 1249px) {}

@media (max-width: 1023px) {
      .admin-dash-card-row {
            grid-template-columns: repeat(2, 1fr);
      }
}

@media (max-width: 767px) {
      .admin-dash-card-row {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}